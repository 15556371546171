<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'Settings',
  page: {
    title: 'Features Settings',
  },
}
</script>
